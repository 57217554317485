var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      style: "width:" + _vm.width + "px",
      attrs: { clearable: "", placeholder: "请选择送货人" },
      on: { change: _vm.hanldeChange },
      model: {
        value: _vm.userId,
        callback: function ($$v) {
          _vm.userId = $$v
        },
        expression: "userId",
      },
    },
    _vm._l(_vm.userList, function (i, idx) {
      return _c("el-option", {
        key: idx,
        attrs: { value: i.id, label: i.name },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }