<template>
  <el-select
    v-model="userId"
    clearable
    :style="'width:' + width + 'px'"
    placeholder="请选择送货人"
    @change="hanldeChange"
  >
    <el-option
      v-for="(i, idx) in userList"
      :key="idx"
      :value="i.id"
      :label="i.name"
    ></el-option>
  </el-select>
</template>
<script>
  import { driverList } from '@/api/advanceOrder'

  export default {
    name: 'DriverSelect',
    components: {},
    props: {
      width: {
        type: [Number, String],
        default: () => 140,
      },
    },
    data() {
      return {
        userId: '',
        userList: [],
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      // 获取送货员下拉
      driverList().then((r) => {
        this.userList = r.data
      })
    },
    methods: {
      hanldeChange(v) {
        this.$emit('change-driver', v)
      },
      clearForm() {
        this.userId = ''
        this.$emit('change-driver', '')
      },
    },
  }
</script>
<style lang="scss" scoped></style>
